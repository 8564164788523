footer {
  margin-top: 100px;
  padding: 10px;
  font-family: "Montserrat", sans-serif;
  font-weight: 370;
  background-color: rgba(0, 0, 0, 0);
  color: black;
  text-align: center;
  font-size: 30px;
  clear: both();
}

.textsizeinfooter {
  margin-top: 30px;
  font-size: 10px;
}
