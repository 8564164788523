.kons_nav {
  height: auto;
  width: 100%;
  position: fixed;
  top: 0;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
  z-index: 999;
  transform: translateY(0%);
}

.kons_nav_scrolled {
  height: auto;
  width: 100%;
  position: fixed;
  top: 0;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
  z-index: 999;
  background-color: white;
}

.kons_but {
  position: relative;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: black;
  text-decoration: none;
  padding: 9px 25px;
  background-color: aqua;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  right: 0;
  z-index: 999;
}

.kons_logo {
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 26px;
  color: aqua;
  margin-left: 27px;
  background-color: rgba(0, 0, 0, 0);
  z-index: 999;
}

.kons_nav_links {
  margin-left: 30px;
  z-index: 999;
}

.kons_info__nav {
  display: flex;
  align-items: stretch;

  flex-direction: row;
  min-width: 660px;
  max-height: 40px;
  margin-right: 15px;
  margin-left: 8px;
}

@media screen and (max-width: 1380px) {
  .kons_nav {
    height: auto;
    width: 100%;
    position: fixed;
    top: 0;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 0);
    transition-duration: 200ms;
    z-index: 999;
    transform: translateY(0%);
  }

  .kons_nav_scrolled {
    backdrop-filter: blur(20px);
    height: auto;
    width: 100%;
    position: fixed;
    top: 0;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 500ms;
    z-index: 999;
    background-color: white;
  }
  .kons_info__nav {
    display: flex;
    align-items: stretch;

    flex-direction: row;
    min-width: 590px;
    max-height: 40px;
    margin-left: 8px;
    margin-right: 0px;
  }
}
@media screen and (max-width: 1400px) {
  .kons_nav {
    font-weight: 500;
    backdrop-filter: blur(20px);
  }
}
@media screen and (max-width: 670px) {
  .kons_info__nav {
    transition-property: all;
    transform: translateY(-100%);
    max-height: 0;
  }

  .kons_info__email {
    transition-property: all;
    transform: translateY(-600%);
    margin-left: 30px;
    visibility: hidden;
  }

  .kons_info__adres {
    transition-property: all;
    transform: translateY(-600%);
    margin-left: 30px;
    visibility: hidden;
  }

  .kons_info__telefon {
    transform: translateY(-600%);
    margin-left: 30px;
    visibility: hidden;
  }
}

.kons_info__email {
  margin-left: 30px;
}

.kons_info__adres {
  margin-left: 30px;
}

.kons_info__telefon {
  margin-left: 30px;
  min-width: 10px;
}

.kons_nav_konsultacje,
.kons_nav_psychoterapia {
  transition-property: all;
  transition-timing-function: cubic-bezier(0, 0, 0, 0);
  transition-duration: 200ms;
  transform: translateX(0%);
}

.kons_nav_konsultacje:hover,
.kons_nav_psychoterapia:hover {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 600ms;
  transform: translateX(+10%);
}
