.gora {
  margin-top: 65px;
  background: #f2f2f2;
}

@media screen and (max-width: 1200px) {
  .gora {
    margin-top: 105px;
    background: #f2f2f2;
  }
}
@media screen and (max-width: 670px) {
  .gora {
    margin-top: 65px;
    transform: translateY(0%);
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 1500ms;
    background: #f2f2f2;
  }
}

.kons_pogrubienie {
  background: #f2f2f2;
  font-weight: 360;
  font-size: 30px;
  margin-bottom: 12px;
  text-align: center;
}

.kons_pogrubienie_stacjonarne {
  background: #f2f2f2;
  font-weight: 360;
  font-size: 25px;
  margin-top: 80px;
  margin-bottom: 12px;
}

.kons_apps {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.kons_textgabinet {
  font-size: 20px;
  display: flex;
  justify-content: center;
}

.kons_domek_adres {
  width: 700px;
  display: flex;
  flex-direction: row;
}

.kons_adres {
  display: flex;
  align-items: center;
}

.kons_zdalne,
.kons_stacjonarne {
  min-width: 230px;
}
