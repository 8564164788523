.terapia_text {
  font-weight: 390;
  background-color: rgba(0, 0, 0, 0);
  color: black;
  text-align: center;
  font-size: 50px;
}

.terapia_text_ins {
  font-weight: 370;
  background-color: rgba(0, 0, 0, 0);
  color: black;
  text-align: center;
  font-size: 20px;
  min-width: 60vw;
  max-width: 30vw;
}

.info_all {
  width: 100%;
  font-family: "Montserrat", sans-serif;
  height: 500px;
  background: #f2f2f2;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding-top: 7%;
  padding-bottom: 7%;
  text-align: center;
}

.info_autor {
  display: flex;
  justify-content: center;
  font-size: 18px;
}

@media screen and (max-width: 670px) {
  .terapia_text {
    font-size: 40px;
  }

  .terapia_text_ins {
    font-size: 17px;
  }

  .info_autor {
    font-size: 16px;
  }
}

@media screen and (max-width: 500px) {
  .info_all {
    height: auto;
  }
}

@media screen and (max-width: 450px) {
  .terapia_text {
    font-size: 30px;
  }

  .terapia_text_ins {
    font-size: 15px;
  }

  .info_autor {
    font-size: 13px;
  }
}
