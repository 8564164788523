.gora {
  margin-top: 65px;
  background: #f2f2f2;
}

.text__and__foto {
  width: 100%;
  margin-top: 5px;
  background: #f2f2f2;
  -webkit-box-shadow: 0px 0px 15px -2px rgba(66, 68, 90, 1);
  -moz-box-shadow: 0px 0px 15px -2px rgba(66, 68, 90, 1);
  box-shadow: 0px 0px 10px -1px rgba(66, 68, 90, 1);
}

.textomnie_wiecej {
  cursor: pointer;
  text-decoration: underline;
}

.big__text {
  font-family: "Montserrat", sans-serif;
  font-weight: 390;
  color: black;
  font-size: 50px;
}

.small__text {
  font-weight: 370;
  color: black;
  font-size: 20px;
}

.opis {
  min-width: 80vh;
}

.zdjecie {
  margin-top: 18px;
  -webkit-box-shadow: 10px 9px 8px -9px rgba(66, 68, 90, 1);
  -moz-box-shadow: 10px 9px 8px -9px rgba(66, 68, 90, 1);
  box-shadow: 10px 9px 8px -9px rgba(66, 68, 90, 1);
}

.sam__img {
  display: flex;
  justify-content: space-around;
}

.banner {
  width: 100%;
  height: 50vh;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex;
  align-items: center;
}

.margin {
  padding-top: 100px;
}

.omnie_wiecej {
  cursor: pointer;
  font-weight: initial;
}

@media screen and (max-width: 1403px) {
  .opis {
    min-width: 40vh;
  }
}
@media screen and (max-width: 1200px) {
  .gora {
    margin-top: 105px;
    background: #f2f2f2;
  }

  .small__text {
    font-weight: 370;
    color: black;
    font-size: 15px;
    min-width: 100%;
  }

  .zdjecie {
    min-width: 296px;
  }

  .sam__img {
    display: flex;
    justify-content: space-around;
  }

  .opis {
    min-width: 20vh;
  }
}

.omnie_img {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 991px) {
  .omnie_img {
    height: 400px;
  }
}

@media screen and (max-width: 670px) {
  .gora {
    margin-top: 65px;
    transform: translateY(0%);
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 1500ms;
    background: #f2f2f2;
  }

  .small__text {
    font-weight: 370;
    color: black;
    font-size: 15px;
    min-width: 216px;
  }

  .zdjecie {
    min-width: 180px;
  }

  .text__and__foto {
    width: 100%;
    margin-top: 5px;
    background: #f2f2f2;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
}

@media screen and (max-width: 440px) {
  .omnie_img {
    width: 300px;
    height: auto;
  }
}
