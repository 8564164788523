.btn-back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: none;
}

.button_searchbar {
  height: 60px;
  margin-left: 5px;
  width: 10%;
  background: #03478e;
  color: #fff;
  font-size: 22px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.mystyle {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;

  position: fixed;
  bottom: 20px;
  right: 20px;

  -webkit-box-shadow: 0px 0px 36px -2px #000000;
  box-shadow: 0px 0px 14px -4px #000000;
  background-color: rgba(0, 0, 0, 0.2);
  width: 25px;
  height: 50px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  border: 2px solid #fff;
  border-radius: 20px;
  border-width: 3px;
  border-color: white;
  color: white;
  z-index: 1;
  transform: translateX(0%);
}

.mystyle_scrolled {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;

  position: fixed;
  bottom: 20px;
  right: 20px;

  -webkit-box-shadow: 0px 0px 36px -2px #000000;
  box-shadow: 0px 0px 14px -4px #000000;
  background-color: rgba(0, 0, 0, 0.2);
  width: 25px;
  height: 50px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  border: 2px solid #fff;
  border-radius: 20px;
  border-width: 3px;
  border-color: white;
  color: white;
  z-index: -1;
  transform: translateX(600%);
}
