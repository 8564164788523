.rowbc {
  background-color: #f2f2f2;
  width: 100%;
  height: 100%;
}

.colbc {
  background-color: #f2f2f2;
  width: 100%;
  height: 100%;
}

.ofertatext {
  font-family: "Montserrat", sans-serif;
  font-weight: 390;
  font-size: 55px;
  text-align: center;
}

.oferta {
  font-family: "Montserrat", sans-serif;
  /* width: 100%; */
  height: 500px;
  background: #f2f2f2;
  /* background-image: linear-gradient(
      22deg,
      #27d86c 0%,
      #26caf8 50%,
      #c625d0 100%
    ); */
  align-items: center;
  display: flex;
  justify-content: space-evenly;
  /* margin-bottom: 10px; */
}

.oferta_text {
  display: flex;
  justify-content: center;
}

.cena {
  font-size: 20px;
}

.dla_par {
  /* width: 550px; */
  height: auto;
  max-width: 660px;
  /* min-width: 250px; */
  text-align: left;
  font-size: 23px;
  border: 1px black solid;
  background-color: rgba(0, 0, 0, 0);
  padding: 2%;
  cursor: pointer;
}

.margin {
  padding-top: 100px;
}

.oferta_wiecej {
  cursor: pointer;
  text-decoration: underline;
}
