.dos_nav {
  height: auto;
  width: 100%;
  position: fixed;
  top: 0;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
  z-index: 999;
  background-color: transparent;
}

/* .dos_nav_sec {
  height: auto;
  width: 100%;
  position: fixed;
  top: 0;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
  z-index: 999;
  transform: translateY(0%);
  background: #f2f2f2;
} */

.dos_nav_scrolled {
  height: auto;
  width: 100%;
  position: fixed;
  top: 0;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
  z-index: 999;
  background-color: white;
}

.dos_but {
  position: relative;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: black;
  text-decoration: none;
  padding: 9px 25px;
  background-color: aqua;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  right: 0;
  z-index: 999;
}

.dos_logo {
  cursor: pointer;
  /* width: 100%; */
  /* margin-right: auto; */
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 26px;
  color: aqua;
  margin-left: 27px;
  background-color: rgba(0, 0, 0, 0);
  z-index: 999;
}

.dos_nav_links {
  margin-left: 30px;
  z-index: 999;
}

.dos_info__nav {
  display: flex;
  align-items: stretch;
  flex-direction: row;
  min-width: 660px;
  max-height: 40px;
  margin-right: 30px;
  margin-left: 8px;
}
@media screen and (max-width: 1400px) {
  .dos_nav {
    font-weight: 500;
    backdrop-filter: blur(20px);
  }
}
@media screen and (max-width: 670px) {
  .dos_info__nav {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 1500ms;
    transform: translateY(-100%);
    max-height: 0;
  }

  .dos_info__email {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 800ms;
    transform: translateY(-600%);
    margin-left: 30px;
    visibility: hidden;
  }

  .dos_info__adres {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 800ms;
    transform: translateY(-600%);
    margin-left: 30px;
    visibility: hidden;
  }

  .dos_info__telefon {
    /* transition-property: all;  */
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 800ms;
    transform: translateY(-600%);
    margin-left: 30px;
    visibility: hidden;
  }
}

.dos_info__email {
  margin-left: 30px;
}

.dos_info__adres {
  margin-left: 30px;
}

.dos_info__telefon {
  margin-left: 30px;
  min-width: 10px;
}

.dos_nav_konsultacje,
.dos_nav_psychoterapia {
  transition-property: all;
  transition-timing-function: cubic-bezier(0, 0, 0, 0);
  transition-duration: 200ms;
  transform: translateX(0%);
}

.dos_nav_konsultacje:hover,
.dos_nav_psychoterapia:hover {
  /* background: #bfbfbf; */
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 600ms;
  transform: translateX(+10%);
}
