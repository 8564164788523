.dos_text_big {
  font-family: "Montserrat", sans-serif;
  font-weight: 390;
  color: black;
  font-size: 35px;
  margin-bottom: 12px;
  display: flex;
  justify-content: center;
}

.dos_text_small {
  font-weight: 370;
  color: black;
  font-size: 20px;
  display: flex;
  justify-content: center;
}
