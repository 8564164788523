.cytat {
    /*las*/
    font-family: "Montserrat", sans-serif;
    /* width: 100%; */
    font-weight: 400px;
    height: 700px;
    display: flex;
    background-image: url('../../assets/cytatbc.webp');
    background-size: cover;
    justify-content: space-around;
    align-items: center;
  }

  /* @media screen and (max-width: 550px) {
    .cytat {
      font-family: "Montserrat", sans-serif;
      width: 100%;
      font-weight: 400;
      height: 400px;
      display: flex;
      background-image: url('../../assets/cytatbc.jpg');
      background-size: cover;
      justify-content: space-around;
      align-items: center;
    }
} */

    .text1 {
        /*to z lasu*/
        font-weight: 600;
        background-color: rgba(0, 0, 0, 0);
        color: #ffffff;
        text-align: center;
        font-size: 20px;
      }

      .text1 {
        font-weight: 600;
        background-color: rgba(0, 0, 0, 0);
        color: #ffffff;
        text-align: center;
        font-size: 20px;
        /* scroll-behavior: none; */
      }

