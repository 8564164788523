.dos_baner {
  /*las*/
  font-family: "Montserrat", sans-serif;
  /* width: 100%; */
  font-weight: 400px;
  height: 400px;
  display: flex;
  background-image: url("../../../assets/cytatbc.webp");
  background-size: cover;
  justify-content: space-around;
  align-items: center;
}

.dos_text1 {
  /*to z lasu*/
  font-weight: 600;
  background-color: rgba(0, 0, 0, 0);
  color: #ffffff;
  text-align: center;
  font-size: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.dos_text1 {
  font-weight: 600;
  background-color: rgba(0, 0, 0, 0);
  color: #ffffff;
  text-align: center;
  font-size: 20px;
  /* scroll-behavior: none; */
}

.dos_gora {
  margin-top: 65px;
  background: #f2f2f2;
}

@media screen and (max-width: 1200px) {
  .dos_gora {
    margin-top: 105px;
    background: #f2f2f2;
  }
}
@media screen and (max-width: 670px) {
  .dos_gora {
    margin-top: 65px;
    transform: translateY(0%);
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 1500ms;
    background: #f2f2f2;
  }
}
