.kontakt_margin {
  padding-top: 100px;
}

.kontakt_maly_tel {
  padding-top: 5px;
  padding-bottom: 5px;
  font-family: "Montserrat", sans-serif;
  font-size: 22px;
  min-width: 165px;
}

.kontakt_maly_email {
  font-family: "Montserrat", sans-serif;
  font-size: 22px;
}

.kontakt_maly_adres {
  padding-top: 5px;
  padding-bottom: 5px;
  font-family: "Montserrat", sans-serif;
  font-size: 22px;
  min-width: 187px;
}

@media screen and (max-width: 900px) {
  .kontakt_maly_tel,
  .kontakt_maly_adres,
  .kontakt_maly_email {
    font-size: 18px;
  }
}
